import React from "react"
import { useStaticQuery, graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import OnePageSection from "../components/section/OnePageSection"
import { QueryPrivacyPolicyProps } from "../types/interface"

const PrivacyPolicy: React.FC<PageProps> = () => {
  const data: QueryPrivacyPolicyProps = useStaticQuery(graphql`
    query {
      gcms {
        privacyPolicies {
          text
          title
          id
          updating {
            html
          }
          whatPurpose {
            html
          }
          yourRights {
            html
          }
          storageTime {
            html
          }
          legalBasis {
            html
          }
          dataType {
            html
          }
          dataStore {
            html
          }
          dataAdmin {
            html
          }
          dataAccess {
            html
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Polityka prywatności" />
      {data.gcms.privacyPolicies.map(
        ({
          title,
          text,
          updating,
          whatPurpose,
          yourRights,
          storageTime,
          legalBasis,
          dataType,
          dataStore,
          dataAdmin,
          dataAccess,
          id,
        }) => {
          const textArray = [
            updating,
            whatPurpose,
            yourRights,
            storageTime,
            legalBasis,
            dataType,
            dataStore,
            dataAdmin,
            dataAccess,
          ].filter(item => item)

          return (
            <OnePageSection
              title={title}
              text={text}
              textArray={textArray}
              key={id}
              id={id}
            />
          )
        }
      )}
    </Layout>
  )
}

export default PrivacyPolicy
